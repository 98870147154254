const AiStrategyBlue = ({ fill = '#EDEDED' }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow_forward - link emphasis">
                <mask id="mask0_63_5502" maskUnits="userSpaceOnUse" x="-1" y="0" width="25" height="25">
                    <rect id="Bounding box" x="-0.00146484" y="0.230469" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_63_5502)">
                    <path id="arrow_forward" d="M16.6255 12.9805H5.24854C5.03572 12.9805 4.85752 12.9087 4.71394 12.7651C4.57034 12.6215 4.49854 12.4433 4.49854 12.2305C4.49854 12.0177 4.57034 11.8395 4.71394 11.6959C4.85752 11.5523 5.03572 11.4805 5.24854 11.4805H16.6255L11.4562 6.3113C11.3075 6.16258 11.2341 5.98855 11.236 5.7892C11.238 5.58983 11.3165 5.41259 11.4716 5.25747C11.6267 5.11261 11.8024 5.03761 11.9985 5.03247C12.1947 5.02734 12.3703 5.10234 12.5254 5.25747L18.8658 11.5978C18.9594 11.6914 19.0254 11.7901 19.0639 11.894C19.1023 11.9978 19.1216 12.11 19.1216 12.2305C19.1216 12.351 19.1023 12.4632 19.0639 12.567C19.0254 12.6709 18.9594 12.7696 18.8658 12.8632L12.5254 19.2036C12.387 19.342 12.2155 19.4129 12.011 19.4161C11.8065 19.4193 11.6267 19.3484 11.4716 19.2036C11.3165 19.0485 11.2389 18.8703 11.2389 18.669C11.2389 18.4677 11.3165 18.2895 11.4716 18.1344L16.6255 12.9805Z" fill={fill} />
                </g>
            </g>
        </svg>

    );
};

export default AiStrategyBlue;